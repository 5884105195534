import './App.css';
import { useState } from 'react';

const banks = [
  {
  id: 'Credit Agricole',
  icon: 'https://i.imgur.com/orRse7U.png',
  details: [{
    title: 'Terms & Conditions',
    data: [
      'Minimum Net Salary EGP 3000.00',
      'Salary should be transferred to his acount 3 months before the loan approval Or One Year Stamped & signed Bank statement from his current bank.'
    ],
  }, {
    title: 'Loan installment (Years)',
    data: ['1 or 3 or 5 or 7(16.50%) years']
  }, {
    title: 'Required Documents',
    data: [
      'Valid National ID',
      'HR Letter',
      'One year stamped & signed bank statement from their current bank.'
    ]
  }, {
    title: 'Contact Person Name',
    data: ['Ms. Rana Effat']
  }, {
    title: 'Contact Person Mobile No.',
    data: ['01007340503']
  }],
}, {
  id: 'NBK',
  icon: 'https://i.imgur.com/JVaQIw7.png',
  details: [{
    title: 'Terms & Conditions',
    data: [
      'No Minimum Net Salary required.',
      'Salary should transfer to his account 3 months before to get the loan approval.'
    ],
  }, {
    title: 'Loan installment (Years)',
    data: ['Up to 10 years']
  }, {
    title: 'Required Documents',
    data: [
      'Valid National ID',
      'HR Letter',
    ]
  }, {
    title: 'Contact Person Name',
    data: ['Mr. Ahmed Elaraby']
  }, {
    title: 'Contact Person Mobile No.',
    data: ['01069070248']
  }],
}, {
  id: 'Alex Bank',
  icon: 'https://i.imgur.com/J8eTbcR.png',
  details: [{
    title: 'Terms & Conditions',
    data: [
      'Minimum Net Salary EGP 2000.00',
      'Salary should be transferred to his account 3 months before the loan approval.'
    ],
  }, {
    title: 'Loan installment (Years)',
    data: ['Up to 12 years']
  }, {
    title: 'Required Documents',
    data: [
      'Valid National ID',
      'HR Letter',
      'Recent Electricty / Gas receipt.( ايصال غاز او كهرباء)'
    ]
  }, {
    title: 'Contact Person Name',
    data: ['Mr. Ahmed Hassan']
  }, {
    title: 'Contact Person Mobile No.',
    data: ['01006734028']
  }],
}, {
  id: 'ADIB',
  icon: 'https://i.imgur.com/6exoE1B.png',
  details: [{
    title: 'Terms & Conditions',
    data: [
      'Minimum Net Salary EGP 2500.00',
      'Salary should be transferred to his acount 3 months before the loan approval.'
    ],
  }, {
    title: 'Loan installment (Years)',
    data: ['Up to 5 years']
  }, {
    title: 'Required Documents',
    data: [
      'Valid National ID',
      'HR Letter',
    ]
  }, {
    title: 'Contact Person Name',
    data: ['Mr. Ashraf Dous']
  }, {
    title: 'Contact Person Mobile No.',
    data: ['01227711139']
  }],
}, {
  id: 'Attijariwafa',
  icon: 'https://i.imgur.com/gaQ5WWU.png',
  details: [{
    title: 'Terms & Conditions',
    data: [
      'Minimum Net Salary EGP 3000.00',
      'Salary should transfer to his account 4 months before to get the loan approval.'
    ],
  }, {
    title: 'Loan installment (Years)',
    data: ['Up to 9 years']
  }, {
    title: 'Required Documents',
    data: [
      'Valid National ID',
      'HR Letter',
      'Recent Electricty / Gas receipt.( ايصال غاز او كهرباء)'
    ]
  }, {
    title: 'Contact Person Name',
    data: ['Ms. Mariam Yaany']
  }, {
    title: 'Contact Person Mobile No.',
    data: ['0110003664']
  }],
}]

function App() {
  const [currentBank, setCurrentBank] = useState(banks[0]);
  const onClick = id => () => setCurrentBank(id);
  return (
    <div className="App">
      <img src={'https://i.imgur.com/btPYyao.png'} alt="ibs" className='logo' />
      <div className='header'>
        <div className='header__title'>Bank Loan Requirements</div>
        <div className='header__subtitle'>Published 6/2/2023</div>
      </div>
      <div className='bank-buttons'>
        {banks.map(bank => {
          const active = bank.id === currentBank.id;
          return (
          <div className={`bank-button ${active && 'bank-button__active'}`} key={bank.id} onClick={onClick(bank)}>
            <img src={bank.icon} alt={bank.title} />
            <div>{bank.id}</div>
          </div>
        )})}
      </div>
      <div className='details'>
        {currentBank.details.map(detail => (
          <div className='detail' key={detail.title}>
            <div className='detail__title'>{detail.title}</div>
            <div className='detail__data'>
              {detail.data.map((singleData, index) => (
                <div key={singleData}>
                  <div className='detail__data__single'>{singleData}</div>
                  {index + 1 < detail.data.length && <div className='line'></div>}
                </div>
              ))}
              </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
